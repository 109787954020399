<template>
  <div>
    
  <div class="bar" v-if="!inMaintenance" id="top-1">
     <div class="p-0" v-if="showRun && isHomePage && isMarketPage">
  
    <div class="marquee bg-red">
      <p class="marquee_text">
        
          {{ runMessage }}
      </p>
    </div>
  </div>
    <!-- Navbar Start -->
    <div :style="{ 
    height: isHomePage ? '60px' : isMarketPage ? '60px' : onSearch ? '200px' : '100px' 
}"
 class="sticky-top topbar" v-if="!isLoginPage">
      <div class="table-1-web d-none d-sm-block">
      <nav class="navbar navbar-expand-lg p-2 pt-1">
        <div>
          <a href="/"  class="logo navbar-brand d-flex g-0 align-items-center text-center  ">
            <img class="py-1" src="../src/assets/img/vayal-new-logo.png" loading="lazy" alt="VayalAgroLogo" />
            <h2 class="pt-2">{{translations.logotxt}}&#8482;</h2>
          </a>
        </div>
       
        <div class="searchproduct   "  v-if="!isHomePage && !isMarketPage">
          <div class="search-bar">
            <div class="locationhead">
              <div class="input-container locationcontainer" ref="locationInputContainer">
                <div class="d-flex">
                  <span class="ps-3 pt-1 pb-2 locationicon">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                      width="20" height="20" viewBox="0 0 256 256" xml:space="preserve">
                      <defs />
                      <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;"
                        transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
                        <path
                          d="M 45 1.802 c 16.583 0 30.075 13.491 30.075 30.075 c 0 7.102 -2.538 14.004 -7.145 19.434 L 45 78.317 L 22.07 51.311 c -4.608 -5.43 -7.145 -12.332 -7.145 -19.434 C 14.925 15.294 28.417 1.802 45 1.802 M 45 45.692 c 8.222 0 14.912 -6.689 14.912 -14.912 c 0 -8.222 -6.689 -14.912 -14.912 -14.912 S 30.088 22.557 30.088 30.78 C 30.088 39.002 36.778 45.692 45 45.692 M 45 0 C 27.395 0 13.123 14.272 13.123 31.877 c 0 7.86 2.858 15.043 7.573 20.6 L 45 81.101 l 24.304 -28.624 c 4.716 -5.558 7.573 -12.741 7.573 -20.6 C 76.877 14.272 62.605 0 45 0 L 45 0 z M 45 43.889 c -7.24 0 -13.11 -5.869 -13.11 -13.11 c 0 -7.24 5.869 -13.11 13.11 -13.11 s 13.11 5.869 13.11 13.11 C 58.11 38.02 52.24 43.889 45 43.889 L 45 43.889 z"
                          style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #4E7300; fill-rule: nonzero; opacity: 1;"
                          transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                        <path
                          d="M 58.814 71.531 l -1.575 1.575 c 9.772 1.532 15.583 4.707 15.583 7.094 c 0 3.261 -10.838 7.997 -27.822 7.997 S 17.178 83.461 17.178 80.2 c 0 -2.387 5.811 -5.562 15.583 -7.094 l -1.575 -1.575 c -9.401 1.643 -15.81 4.907 -15.81 8.669 c 0 5.412 13.263 9.8 29.625 9.8 c 16.361 0 29.625 -4.388 29.625 -9.8 C 74.625 76.439 68.215 73.174 58.814 71.531 z"
                          style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #4E7300; fill-rule: nonzero; opacity: 1;"
                          transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                      </g>
                    </svg>
                  </span>
                  <input v-model="location" @input="updateSearchResults" @focus="updateSearchResults" @click="onSearchResultsClick"
                    placeholder="Tamilnadu" class="search-input location w-100 pb-2 pt-2" type="search" id="example-search-input4" autocomplete="off"/>
                </div>
                <ul v-if="searchResults.length" class="search-results" >
                  <li v-for="result in searchResults" :key="result.market_id"
                    @click="selectSearchResult(result, 'location')"
                    >
                    <span>{{  selectedLanguage === "en"
                            ? result.market_name
                            : result.tn_name 
                          }}</span>
                  </li>
                  
                </ul>
              </div>
            </div>
            <div class="d-flex searchhead">
              <div class="input-container productcontainer" ref="productInputContainer">
                <input v-model="product" @input="updateProductSearchResults" type="text"
                  placeholder="Search Your 'Agriculture' Products & Equipments " class="search-input product w-100 py-2" />
                <ul v-if="productSearchResults.length" class="search-results">
                  <li v-for="result in productSearchResults" :key="result.category_id || result.sub_category_id"
                    @click="selectSearchResult(result, 'product')">
                    <span>
                     {{ selectedLanguage === "en"
                            ?
                        (result.sub_category_name || result.category_name) : (result.sub_category_tamil_name || result.category_tamil_name) 
                      }}
                    </span>
                  </li>
                </ul>
              </div>
              <span @click="submitSearch" :disabled="product.trim() === ''" class="submit-button">
                <span class="submit-button-pro">Search</span>
              </span>
            </div>
          </div>
        </div>
        <div class="collapse navbar-collapse m-0 p-0 " id="navbarCollapse">
          <div class="navbar-nav ms-auto  p-lg-0 ">
            <ul class="nav-links m-0 p-0 pb-2 " >
              
              <li class="dropdown mx-1" id="Hide-content" v-if="!isHomePage">
                <a >
{{ translations.category }}                  <!--SVG dropdown icon-->
                  <svg class="icon" width="14" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                    <path
                      d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z" />
                  </svg>
                </a>
                <!-- Start of Dropdown -->
                <ul class="dropdown-nav">
                  <li>
                    <router-link :to="getModifiedCategoryLink('agriculture products', this.$route.params.location)">
                      {{ translations.farmProducts }}
                    </router-link>
                  </li>
                  
                  <li>
                    <router-link :to="getModifiedCategoryLink('agriculture equipments', this.$route.params.location)">{{translations.farmEquipments}}</router-link>
                  </li>
                  <li>
                    <router-link :to="getModifiedCategoryLink('livestocks', this.$route.params.location)">{{translations.Livestocks}}</router-link>
                  </li>
                </ul>
              </li>
              <li class="mx-2 text-nowrap" id="Hide-content" v-if="!isHomePage">
                <router-link to="/market-price" active-class="active-link text-nowrap">
                  {{ translations.marketPrice
                  }}
                </router-link>
              </li>
              <li class="mx-1">
                <router-link to="/blogs" active-class="active-link">
                  {{
                    translations.blogs
                  }}
                </router-link>
              </li>
              <li class="mx-1 text-nowrap">
                <router-link to="/about" active-class="active-link">
                  {{
                    translations.about
                  }}
                </router-link>
              </li>
              <li class="mx-1">
                <router-link @click="scrollToTop" to="/contact" active-class="active-link">
                  {{ translations.contact
                  }}
                </router-link>
              </li>
              <!-- Include the LanguageDropdown component here -->
              <li class="mx-1 d-flex">
                <svg height="20" viewBox="0 0 48 48" width="20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0h48v48h-48z" fill="none" />
                  <path
                    d="M23.99 4c-11.05 0-19.99 8.95-19.99 20s8.94 20 19.99 20c11.05 0 20.01-8.95 20.01-20s-8.96-20-20.01-20zm13.85 12h-5.9c-.65-2.5-1.56-4.9-2.76-7.12 3.68 1.26 6.74 3.81 8.66 7.12zm-13.84-7.93c1.67 2.4 2.97 5.07 3.82 7.93h-7.64c.85-2.86 2.15-5.53 3.82-7.93zm-15.48 19.93c-.33-1.28-.52-2.62-.52-4s.19-2.72.52-4h6.75c-.16 1.31-.27 2.64-.27 4 0 1.36.11 2.69.28 4h-6.76zm1.63 4h5.9c.65 2.5 1.56 4.9 2.76 7.13-3.68-1.26-6.74-3.82-8.66-7.13zm5.9-16h-5.9c1.92-3.31 4.98-5.87 8.66-7.13-1.2 2.23-2.11 4.63-2.76 7.13zm7.95 23.93c-1.66-2.4-2.96-5.07-3.82-7.93h7.64c-.86 2.86-2.16 5.53-3.82 7.93zm4.68-11.93h-9.36c-.19-1.31-.32-2.64-.32-4 0-1.36.13-2.69.32-4h9.36c.19 1.31.32 2.64.32 4 0 1.36-.13 2.69-.32 4zm.51 11.12c1.2-2.23 2.11-4.62 2.76-7.12h5.9c-1.93 3.31-4.99 5.86-8.66 7.12zm3.53-11.12c.16-1.31.28-2.64.28-4 0-1.36-.11-2.69-.28-4h6.75c.33 1.28.53 2.62.53 4s-.19 2.72-.53 4h-6.75z"
                    fill="#4E7300" />
                </svg>
                <select class="language-select town" v-model="selectedLanguage">
                  <option value="en">ENGLISH</option>
                  <option value="ta">தமிழ்</option>
                </select>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      </div>

      <div class="d-sm-none">
      <nav class="navbar navbar-expand-lg p-2 pt-1">
        <div>
          <a href="/"  class="logo navbar-brand d-flex g-0 align-items-center text-center  ">
            <img class="py-1" src="../src/assets/img/vayal-new-logo.png" loading="lazy" alt="VayalAgroLogo" />
            <h2 class="pt-2">VAYALAGRO<span>&#8482;</span></h2>
          </a>
        </div>
       
        <div class="searchproduct "  v-if="!isHomePage && !isMarketPage">
          <div class="search-bar">
            <div class="locationhead" id="locationcontainer" >
              <div class="input-container locationcontainer" ref="locationInputContainer">
                <div class="d-flex">
                  <span class="ps-3 py-1 locationicon">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                      width="20" height="20" viewBox="0 0 256 256" xml:space="preserve">
                      <defs />
                      <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;"
                        transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
                        <path
                          d="M 45 1.802 c 16.583 0 30.075 13.491 30.075 30.075 c 0 7.102 -2.538 14.004 -7.145 19.434 L 45 78.317 L 22.07 51.311 c -4.608 -5.43 -7.145 -12.332 -7.145 -19.434 C 14.925 15.294 28.417 1.802 45 1.802 M 45 45.692 c 8.222 0 14.912 -6.689 14.912 -14.912 c 0 -8.222 -6.689 -14.912 -14.912 -14.912 S 30.088 22.557 30.088 30.78 C 30.088 39.002 36.778 45.692 45 45.692 M 45 0 C 27.395 0 13.123 14.272 13.123 31.877 c 0 7.86 2.858 15.043 7.573 20.6 L 45 81.101 l 24.304 -28.624 c 4.716 -5.558 7.573 -12.741 7.573 -20.6 C 76.877 14.272 62.605 0 45 0 L 45 0 z M 45 43.889 c -7.24 0 -13.11 -5.869 -13.11 -13.11 c 0 -7.24 5.869 -13.11 13.11 -13.11 s 13.11 5.869 13.11 13.11 C 58.11 38.02 52.24 43.889 45 43.889 L 45 43.889 z"
                          style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #4E7300; fill-rule: nonzero; opacity: 1;"
                          transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                        <path
                          d="M 58.814 71.531 l -1.575 1.575 c 9.772 1.532 15.583 4.707 15.583 7.094 c 0 3.261 -10.838 7.997 -27.822 7.997 S 17.178 83.461 17.178 80.2 c 0 -2.387 5.811 -5.562 15.583 -7.094 l -1.575 -1.575 c -9.401 1.643 -15.81 4.907 -15.81 8.669 c 0 5.412 13.263 9.8 29.625 9.8 c 16.361 0 29.625 -4.388 29.625 -9.8 C 74.625 76.439 68.215 73.174 58.814 71.531 z"
                          style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #4E7300; fill-rule: nonzero; opacity: 1;"
                          transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                      </g>
                    </svg>
                  </span>
                  <input v-model="location" @input="updateSearchResults" @focus="updateSearchResults"  @click="onSearchTrue" 
                    placeholder="Tamilnadu" class="search-input location  pb-2 pt-2" type="search" id="example-search-input4" autocomplete="off"/>
                </div>
                <ul v-if="searchResults.length" class="search-results" >
                  <li v-for="result in searchResults" :key="result.market_id"
                    @click="selectSearchResult(result, 'location')"
                    >
                    <span>{{ result.market_name }}</span>
                  </li>
                  
                </ul>
              </div>
            </div>
            <div v-if="onSearch" class="searchhead " id="searchhead">
              <div class="input-container productcontainer" ref="productInputContainer">
                <input v-model="product" @input="updateProductSearchResults" type="text"
                  placeholder="Search Your 'Agriculture' Products & Equipments " class="search-input product w-100 py-2" />
                <ul v-if="productSearchResults.length" class="search-results">
                  <li v-for="result in productSearchResults" :key="result.category_id || result.sub_category_id"
                    @click="selectSearchResult(result, 'product')">
                    <span>
                     {{ selectedLanguage === "en"
                            ?
                        (result.sub_category_name || result.category_name) : (result.sub_category_tamil_name || result.category_tamil_name )
                      }}
                    </span>
                  </li>
                </ul>
              </div>
              <span @click="submitSearch" :disabled="product.trim() === ''" class="submit-button">
                <span class="submit-button-pro">{{translations.search}}</span>
              </span>
            </div>
          </div>
        </div>
       
      </nav>
      </div>
      <div class="tog">
        <input type="checkbox" id="toggle" class="input-toggler" v-model="isSidebarOpen" />
        <label for="toggle" class="menu-toggler">
          <span class="menu-toggler-line"></span>
          <span class="menu-toggler-line"></span>
          <span class="menu-toggler-line"></span>
        </label>

        <aside class="sidebar" :class="{ 'is-open': isSidebarOpen }">
          <ul class="menu">
            <li class="mx-2">
              <a href="/" class="menu-link "  @click="closeSidebar" v-if="!isHomePage"> 
                {{
                  translations.home
                }}
              </a>
            </li>
          
            <li class="mx-2">
              <router-link  @click="closeSidebar" class="menu-link" :to="getModifiedCategoryLink('agriculture products', defaultLocationEn)">{{translations.farmProducts}}</router-link>
                </li>
                <li class="mx-2">
                  <router-link  @click="closeSidebar" class="menu-link" :to="getModifiedCategoryLink('agriculture equipments', defaultLocationEn)">{{translations.farmEquipments}}</router-link>
                </li>
                <li class="mx-2">
                  <router-link  @click="closeSidebar" class="menu-link" :to="getModifiedCategoryLink('livestocks', defaultLocationEn)">{{translations.Livestocks}}</router-link>
                </li>
             
            <li class="mx-2">
              <router-link  @click="closeSidebar" to="/market-price" class="menu-link">
                {{ translations.marketPrice
                }}
              </router-link>
            </li>
            <li class="mx-2">
              <router-link  @click="closeSidebar" to="/blogs" class="menu-link">
                {{
                  translations.blogs
                }}
              </router-link>
            </li>
            <li class="mx-2 text-nowrap">
              <router-link  @click="closeSidebar" to="/about" class="menu-link">
                {{
                  translations.about
                }}
              </router-link>
            </li>
            <li class="mx-2">
              <router-link  @click="closeSidebar"  to="/contact" class="menu-link">
                {{ translations.contact
                }}
              </router-link>
            </li>
            <li class="mx-2 my-3">
              <label class="m-0 p-0" for="text"> Language:</label>
       
              <select  class="language-select town m-0 ps-0 mx-3 menu-link custom-select" v-model="selectedLanguage">
                <option value="en">ENGLISH</option>
                <option value="ta">தமிழ்</option>
              </select>
            </li>
          </ul>
        </aside>
      </div>
    </div>
     <div class="router-link" @click="onSearchFalse">
      <router-view :selectedLanguage="selectedLanguage" />
     <BottomAdVue :selectedLanguage="selectedLanguage" />

    </div>
    <FooterPage :selectedLanguage="selectedLanguage" />
  </div>

  <div class="bar" v-else id="top-2">
    <!-- Navbar Start -->
    <div class="sticky-top topbar">
      <nav class="navbar navbar-expand-lg p-2">
        <div>
          <a to=""  class="logo navbar-brand d-flex g-0 align-items-center text-center  ">
            <img class="py-1" src="../src/assets/img/vayal-new-logo.png" loading="lazy" alt="VayalAgroLogo" />
            <h3 class="pt-2">VAYAL</h3>
          </a>
        </div>
       
        <div class="searchproduct   ">
          <div class="search-bar">
            <div class="locationhead">
              <div class="input-container locationcontainer" ref="locationInputContainer">
                <div class="d-flex">
                  <span class="ps-3 pt-2 locationicon">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                      width="20" height="20" viewBox="0 0 256 256" xml:space="preserve">
                      <defs />
                      <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;"
                        transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
                        <path
                          d="M 45 1.802 c 16.583 0 30.075 13.491 30.075 30.075 c 0 7.102 -2.538 14.004 -7.145 19.434 L 45 78.317 L 22.07 51.311 c -4.608 -5.43 -7.145 -12.332 -7.145 -19.434 C 14.925 15.294 28.417 1.802 45 1.802 M 45 45.692 c 8.222 0 14.912 -6.689 14.912 -14.912 c 0 -8.222 -6.689 -14.912 -14.912 -14.912 S 30.088 22.557 30.088 30.78 C 30.088 39.002 36.778 45.692 45 45.692 M 45 0 C 27.395 0 13.123 14.272 13.123 31.877 c 0 7.86 2.858 15.043 7.573 20.6 L 45 81.101 l 24.304 -28.624 c 4.716 -5.558 7.573 -12.741 7.573 -20.6 C 76.877 14.272 62.605 0 45 0 L 45 0 z M 45 43.889 c -7.24 0 -13.11 -5.869 -13.11 -13.11 c 0 -7.24 5.869 -13.11 13.11 -13.11 s 13.11 5.869 13.11 13.11 C 58.11 38.02 52.24 43.889 45 43.889 L 45 43.889 z"
                          style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #4E7300; fill-rule: nonzero; opacity: 1;"
                          transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                        <path
                          d="M 58.814 71.531 l -1.575 1.575 c 9.772 1.532 15.583 4.707 15.583 7.094 c 0 3.261 -10.838 7.997 -27.822 7.997 S 17.178 83.461 17.178 80.2 c 0 -2.387 5.811 -5.562 15.583 -7.094 l -1.575 -1.575 c -9.401 1.643 -15.81 4.907 -15.81 8.669 c 0 5.412 13.263 9.8 29.625 9.8 c 16.361 0 29.625 -4.388 29.625 -9.8 C 74.625 76.439 68.215 73.174 58.814 71.531 z"
                          style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #4E7300; fill-rule: nonzero; opacity: 1;"
                          transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                      </g>
                    </svg>
                  </span>
                  <input v-model="location"  type="text"
                    placeholder="TamilNadu" class="search-input location w-100 pb-2 pt-2" />
                    <button  v-if="location" @click="clearLocation" class="clear-button border-0 p-0 m-0 mt-2 ">x</button>

                </div>
                <ul v-if="searchResults.length" class="search-results">
                  <li class="location-select-user-give-place" v-for="result in searchResults" :key="result.market_id"
                    
                    >
                    <span>{{ result.market_name }}</span>
                  </li>
                  
                </ul>
              </div>
            </div>
            <div class="d-flex searchhead">
              <div class="input-container productcontainer" ref="productInputContainer">
                <input v-model="product"  type="text"
                  placeholder="Search Your 'FARM' Product & Equipments " class="search-input product w-100 py-2" />
                <ul v-if="productSearchResults.length" class="search-results" >
                  <li v-for="result in productSearchResults" :key="result.category_id || result.sub_category_id"
                    >
                    <span>
                      {{
                        result.sub_category_name || result.category_name
                      }}
                    </span>
                  </li>
                </ul>
              </div>
              <span  :disabled="product.trim() === ''" class="submit-button">
                <span class="submit-button-pro">Search</span>
              </span>
            </div>
          </div>
        </div>
        <div class="collapse navbar-collapse m-0 p-0 " id="navbarCollapse">
          <div class="navbar-nav ms-auto  p-lg-0 ">
            <ul class="nav-links m-0 p-0 pb-2 " >
              <!-- Keep only one set of router links for each navigation item -->
              <li class="mx-1" >
                <a to="" active-class="active-link" class="a">
                  {{
                    translations.home
                  }}
                </a>
              </li>
              <!-- <li class="mx-2">
              <a to="/product_to_watch" active-class="active-link">{{translations.category}}</a>
              </li>-->
              <li class="dropdown mx-1">
                <a >
{{ translations.category }}                  <!--SVG dropdown icon-->
                  <svg class="icon" width="14" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                    <path
                      d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z" />
                  </svg>
                </a>
                <!-- Start of Dropdown -->
                <ul class="dropdown-nav">
                  <li id="google-click">
                    <a to="">
                      {{ translations.farmProducts }}
                    </a>
                  </li>
                  
                  <li id="google-click">
                    <a to="">{{translations.farmEquipments}}</a>
                  </li>
                  <li id="google-click">
                    <a to="">{{translations.Livestocks}}</a>
                  </li>
                </ul>
              </li>
              <li class="mx-2 text-nowrap" id="google-click">
                <a to="" active-class="active-link text-nowrap">
                  {{ translations.marketPrice
                  }}
                </a>
              </li>
              <li class="mx-1">
                <a to="" active-class="active-link">
                  {{
                    translations.blogs
                  }}
                </a>
              </li>
              <li class="mx-1 text-nowrap">
                <a to="" active-class="active-link">
                  {{
                    translations.about
                  }}
                </a>
              </li>
              <li class="mx-1">
                <a @click="scrollToTop" to="" active-class="active-link">
                  {{ translations.contact
                  }}
                </a>
              </li>
              <!-- Include the LanguageDropdown component here -->
              <li class="mx-1 d-flex">
                <svg height="20" viewBox="0 0 48 48" width="20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0h48v48h-48z" fill="none" />
                  <path
                    d="M23.99 4c-11.05 0-19.99 8.95-19.99 20s8.94 20 19.99 20c11.05 0 20.01-8.95 20.01-20s-8.96-20-20.01-20zm13.85 12h-5.9c-.65-2.5-1.56-4.9-2.76-7.12 3.68 1.26 6.74 3.81 8.66 7.12zm-13.84-7.93c1.67 2.4 2.97 5.07 3.82 7.93h-7.64c.85-2.86 2.15-5.53 3.82-7.93zm-15.48 19.93c-.33-1.28-.52-2.62-.52-4s.19-2.72.52-4h6.75c-.16 1.31-.27 2.64-.27 4 0 1.36.11 2.69.28 4h-6.76zm1.63 4h5.9c.65 2.5 1.56 4.9 2.76 7.13-3.68-1.26-6.74-3.82-8.66-7.13zm5.9-16h-5.9c1.92-3.31 4.98-5.87 8.66-7.13-1.2 2.23-2.11 4.63-2.76 7.13zm7.95 23.93c-1.66-2.4-2.96-5.07-3.82-7.93h7.64c-.86 2.86-2.16 5.53-3.82 7.93zm4.68-11.93h-9.36c-.19-1.31-.32-2.64-.32-4 0-1.36.13-2.69.32-4h9.36c.19 1.31.32 2.64.32 4 0 1.36-.13 2.69-.32 4zm.51 11.12c1.2-2.23 2.11-4.62 2.76-7.12h5.9c-1.93 3.31-4.99 5.86-8.66 7.12zm3.53-11.12c.16-1.31.28-2.64.28-4 0-1.36-.11-2.69-.28-4h6.75c.33 1.28.53 2.62.53 4s-.19 2.72-.53 4h-6.75z"
                    fill="#4E7300" />
                </svg>
                <select class="language-select town" v-model="selectedLanguage">
                  <option value="en">ENGLISH</option>
                  <option value="ta">தமிழ்</option>
                </select>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div class="tog">
        <input type="checkbox" id="toggle" class="input-toggler" v-model="isSidebarOpen" />
        <label for="toggle" class="menu-toggler">
          <span class="menu-toggler-line"></span>
          <span class="menu-toggler-line"></span>
          <span class="menu-toggler-line"></span>
        </label>

        <aside class="sidebar" :class="{ 'is-open': isSidebarOpen }">
          <ul class="menu">
            <li class="mx-2">
              <a to="" class="menu-link "  @click="closeSidebar">
                {{
                  translations.home
                }}
              </a>
            </li>
          
            <li class="mx-2">
              <a  @click="closeSidebar" class="menu-link" to="">{{translations.farmProducts}}</a>
                </li>
                <li class="mx-2">
                  <a  @click="closeSidebar" class="menu-link" to="">{{translations.farmEquipments}}</a>
                </li>
                <li class="mx-2">
                  <a  @click="closeSidebar" class="menu-link" to="">{{translations.Livestocks}}</a>
                </li>
             
            <li class="mx-2">
              <a  @click="closeSidebar" to="" class="menu-link">
                {{ translations.marketPrice
                }}
              </a>
            </li>
            <li class="mx-2">
              <a  @click="closeSidebar" to="" class="menu-link">
                {{
                  translations.blogs
                }}
              </a>
            </li>
            <li class="mx-2 text-nowrap">
              <a  @click="closeSidebar" to="" class="menu-link">
                {{
                  translations.about
                }}
              </a>
            </li>
            <li class="mx-2">
              <a  @click="closeSidebar"  to="" class="menu-link">
                {{ translations.contact
                }}
              </a>
            </li>
            <li class="mx-2 my-3">
              <label class="m-0 p-0" for="text"> Language:</label>
       
              <select  class="language-select town m-0 ps-0 mx-3 menu-link custom-select" v-model="selectedLanguage">
                <option value="en">ENGLISH</option>
                <option value="ta">தமிழ்</option>
              </select>
            </li>
          </ul>
        </aside>
      </div>
    </div>
    <div class="a">
      <!-- <router-view :selectedLanguage="selectedLanguage" /> -->
      <MaintanacePage :selectedLanguage="selectedLanguage" />

    </div>
  </div>
</div>
</template>

<script>

import FooterPage from "@/components/FooterPage.vue";
import { en, ta } from "@/translations";
import BottomAdVue from './components/homeaddon/BottomAd.vue';
import axios from 'axios';
import MaintanacePage from '@/components/MaintanacePage.vue';
import { useUserStore } from './stores/userStore'

export default {
  name: "app",
  components: {
    MaintanacePage,
    FooterPage,
    BottomAdVue
    // LanguageDropdown, // Include the LanguageDropdown component
  },
  data() {
    return {
      translations: en,
      location: "",
      product: "",
      searchResults: [],
      productSearchResults: [],
      animate: false,
      isSidebarOpen: false,
      isHomePage: true,
      isLoginPage: true,
      isMarketPage: true,
      onSearch: false,
      isNameSelected: false,
      isLocSelected: true,
      marketId: 0,
      defaultLocationEn: "",
      defaultLocationTa: "",
      defaultProductEn: "",
      defaultProductTa: "",
      inMaintenance: false,
      showRun: false,
      runMessage: '',
       locationMatch: false,
    };
    
  },
  created() {
    console.log('created in app.vue')
    this.checkHomePage();
    this.checkLoginPage();
    this.checkMaintenanceStatus();
   
    this.searchResults = []
    this.productSearchResults = []

    this.isNameSelected = false
    this.isLocSelected = true
  
    // Load the selected language from localStorage when the component is created
    const storedLanguage = localStorage.getItem("selectedLanguage");
    if (storedLanguage) {
      this.selectedLanguage = storedLanguage;
    }
  },
  computed: {
    selectedLanguage: {
      get() {
        return this.$data.selectedLanguage || "en";
      },
      set(newLang) {
        // Update the selected language in localStorage and reactivity
        localStorage.setItem("selectedLanguage", newLang);

        // Set a timeout to clear the selected language from localStorage after 5 minutes
        setTimeout(() => {
          localStorage.removeItem("selectedLanguage");
        }, 10 * 60 * 1000); // 5 minutes in milliseconds

        this.$data.selectedLanguage = newLang;

        // Update the translations
        if (newLang === "en") {
          this.translations = en;
        } else if (newLang === "ta") {
          this.translations = ta;
        }
      }
    }
  },
  methods: {
//   refreshPage(){
//   this.$router.push({ name: "Home" });
//   this.scrollToTop();
//   // this.$router.go(0); 
// },
scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    closePopup() {
        this.showRun = false;
      },
      fetchPopup() {
        // Fetch data from the API
        fetch('https://vaiyal-app.herokuapp.com/maintenance?type=running')
          .then((response) => response.json())
          .then((data) => {
            if (data.status === 'success' && data.data.isactive) {
                              this.showRun = true;

                this.img = data.data.img;
                this.link = data.data.link;
                this.runMessage = data.data.msg;
                          console.log("running",this.runMessage)

            } else {
              console.log('Failed to fetch data:', data.msg);
            }
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      },
   checkHomePage() {
      this.isHomePage = this.$route.name === 'Home'
    },
    checkMarketPage() {
    this.isMarketPage = ['marketPrice', 'pricesublist', 'pricesublist1', 'AllRecent'].includes(this.$route.name);
},
   checkLoginPage() {
      if (this.$route.name === 'LoginAccount' || this.$route.name === 'AccountDelete') {
        this.isLoginPage = true;
      } else {
        this.isLoginPage = false;
      }
    },
    async checkMaintenanceStatus() {
      try {
        const response = await axios.get('https://vaiyal-app.herokuapp.com/maintenance?type=maintenance');
        this.inMaintenance = response.data.data.isactive === true;
      } catch (error) {
        console.error('Error fetching maintenance status:', error);
      }
    },
    closeSidebar() {
      this.isSidebarOpen = false;
    },
    clearLocation() {
      this.location = "";
      this.defaultLocationEn = "";
      this.searchResults = [];
    },
    getModifiedCategoryLink(category, locationData) {
      locationData = locationData?.startsWith('-') ? locationData?.split('-')[1] || 'tamilnadu' : locationData || 'tamilnadu'
      return {
        name: 'FarmPage',
        params: {
          category: category.replace(/\s+/g, '-'),
          location: locationData || "tamilnadu", // Default value if locationData is not provided
        },
      };
    },
    onDocumentClick(event) {
      if (this.$refs.locationInputContainer && !this.$refs.locationInputContainer.contains(event.target)) {
        this.searchResults = [];
      }
      if (this.$refs.productInputContainer && !this.$refs.productInputContainer.contains(event.target)) {
        this.productSearchResults = [];
      }
    },
    async updateSearchResults(direct = null) {
     
      console.log('searching location...',this.location)
      this.filterNumbers();
      try {
        this.defaultLocationEn = this.location
        const response = await fetch(
          `https://vaiyal-app.herokuapp.com/get/location?search=${this.location.trim()}`
        );
        const data = await response.json();
console.log("data-app",data)
        if (data.status === "success") {
          // Filter items where "market_name" is present
          if (direct == 'direct') {
            console.log('direct')
            if(data.data.length){
  console.log('data.data.length')
           for (let dist of data.data){
            if (dist.market_name.replace(/\s+/,"-").toLowerCase() == this.location.trim()){
               this.updateMarketId(dist.market_id)
               this.defaultLocationEn = dist.market_name;
               this.defaultLocationTa = dist.tn_name;
              this.locationMatch = true;
              if(this.selectedLanguage == "ta"){
                 this.location = this.defaultLocationTa;
              }
               break
            }
           if(!this.locationMatch){
            console.log("first push",this.location)
             this.$router.push({ name: "Error" });
           }
            
           }

             }
             else{
              // console.log("this.$router.push({ });",this.$route.params.location)
              if(this.$route.params.location !== "tamilnadu" && !(this.$route.name === "livestockdetails" || this.$route.name === "resultproduct" || this.$route.name === "equipment" )
              ){
                console.log("diracr-check",this.$route)
                 console.log("third push",this.$route.params.location !== "tamilnadu")
                this.$router.push({ name: "Error" });
              }
              
            }
          } else {
            console.log('loc searching...')

          this.searchResults = data.data.filter(item => item.market_name !== undefined);

          }
        } else {
          console.error("Error fetching search results:", data.msg);
        }
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    },
      filterNumbers() {
      this.location = this.location.replace(/[0-9]/g, '');
    },
    async updateProductSearchResults() {
  this.productSearchResults = [];
  this.filterOutNumbers();
  
  try {
    this.defaultProductEn = this.product;
    const response = await fetch(
      `https://vaiyal-app.herokuapp.com/get/lists?search=${this.product}`
    );
    const data = await response.json();

    if (data.status === "success") {
      this.productSearchResults = data.data.filter(
        (item) => item.category_type !== "market price"
      );
    } else {
      console.error("Error fetching product search results:", data.msg);
    }
  } catch (error) {
    console.error("Error fetching product search results:", error);
  }
}
,
     filterOutNumbers() {
      this.product = this.product.replace(/[0-9]/g, '');
    },  
    onSearchTrue() {
      this.onSearch = true
            // alert('onSearchTrue')

    },
    onSearchFalse() { 
      this.onSearch = false
    },
    
    onSearchResultsClick() {
      // console.log('loc to false')
    this.isNameSelected = false
    this.isLocSelected = false
    },
    selectSearchResult(result, field) {
  // Update the route based on the field
  const routeParams = {};

  // Check if the field is 'location'
  if (field === "location") {
    console.log( this.defaultLocationEn );
    this.location = this.selectedLanguage === 'en' ? result.market_name : result.tn_name
    this.defaultLocationEn = result.market_name;
    this.defaultLocationTa = result.tn_name;
    this.isLocSelected = true;
    this.searchResults = []
    // Assign market_id and market_place_id properties from the result object
    routeParams.market_id = result.market_id;
    this.marketId = result.market_id
    routeParams.market_name = result.market_name;

  } else if (field === "product") {
    this.product = this.selectedLanguage === 'en' ? result.sub_category_name ?? result.category_name : result.sub_category_tamil_name ?? result.category_tamil_name;
    this.isNameSelected = true;
    this.productSearchResults = []
    routeParams.product = result.sub_category_name ?? result.category_name;
    this.defaultProductEn = result.sub_category_name ?? result.category_name;
    this.defaultProductTa = result.sub_category_tamil_name ?? result.category_tamil_name;
    this.$route.params.category = result.category_type.replace(/\s+/g, '-').toLowerCase().replace('farm', 'agriculture').replace('live-stocks', 'livestocks');
    this.$route.params.product = result.sub_category_name?.replace(/\s+/g, '-')?.toLowerCase() ?? result.category_name.replace(/\s+/g, '-').toLowerCase();
    this.$route.params.categoryName = result.sub_category_name ? result.category_name.replace(/\s+/g, '-').toLowerCase() : null;
  }
 
},
updateMarketId(id = null) {
  console.log('updating mId: ', id)
  const userStore = useUserStore()
  userStore.updateMarketId(id || this.marketId);
  if (id) this.marketId = id
},
submitSearch() {
  
  // When location is not selected and not typed anything, it should default to Tamilnadu
 if ((!this.isLocSelected && (this.location && this.location.trim() !== "")) || (!this.defaultLocationEn && !this.defaultProductEn) && (this.$route.params.location === "tamilnadu" && this.location.trim() === "tamilnadu")) {
  
    alert("Please select the correct location or product before searching.");
    return;
  
}

  this.$route.params.id = null;
  let params = { ...this.$route.params,
    location: (this.defaultLocationEn && this.defaultLocationEn.trim()) ? this.defaultLocationEn.trim() : "tamilnadu",
    product: (this.defaultProductEn && this.defaultProductEn.trim()) ? this.defaultProductEn.replace(/\s+/g, '-') : (this.$route.params.product ? this.$route.params.product : ''),
    };
  params.product ? params.product : (this.defaultProductEn && this.defaultProductEn.trim()) ? this.defaultProductEn.replace(/\s+/g, '-') : '';
  console.log('params in app.vue:', params)
  const name = params.product || this.$route.name == 'resultproduct' || this.$route.name == 'equipment' || this.$route.name == 'livestockdetails' ? 'AllList' : this.$route.params.name
  this.$router.push({
    name: name,
    params: params,
  });
},
setSearchParamsFromRoute() {
      if (this.$route.params.location) {
        // if (this.$route.name !== 'resultproduct' && this.$route.name !== 'equipment' && this.$route.name == 'livestockdetails') {
          this.location = this.$route.params.location.trim().replace(/-/g,' ');
          this.defaultLocationEn = this.location
          console.log("check 404")
          this.updateSearchResults("direct");
        // }
      }
      if (this.$route.params.product && (this.$route.name == "AllList")  ) {
        this.product = this.$route.params.product.replace(/-/g,' ');
        this.defaultProductEn = this.product
      }
      else{
        this.defaultProductEn = "",
          this.product = "";
      }
      console.log(this.$route.name,'route params:',this.$route.params)
    },
    
    startAnimation() {
      // Trigger CSS animation after Vue has rendered the data
      this.$nextTick(() => {
        // Add a class to start the CSS animation
        this.animate = true;
      });
    },
    toggleSearchHead() {
    const searchHead = document.querySelector(".searchhead");
    const dnone = document.querySelector(".d-block");
    if (dnone) {
      searchHead.classList.add("d-none");
    }
    else{
      searchHead.classList.add("d-block"); 
    }
  }
  },
  watch: {
    '$route.params': {
      handler: 'setSearchParamsFromRoute',
      immediate: true,
      deep: true
    },
  selectedLanguage(newLang) {
      if (newLang === 'en') {
        this.location = this.defaultLocationEn;
        this.product = this.defaultProductEn;
      } else if (newLang === 'ta') {
        this.location = this.defaultLocationTa;
        this.product = this.defaultProductTa;
      }
    
    },
    // Watch for route changes and update isHomePage accordingly
    '$route': function() {
      this.checkHomePage();
      this.checkLoginPage();
      this.checkMarketPage();
    },
  isSidebarOpen(newValue) {
      if (newValue) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
  mounted() {
     this.checkHomePage(); // Check on initial load
    this.$watch('$route', this.checkHomePage); 
     this.fetchPopup();
     this.searchResults = []
    this.productSearchResults = []
    document.addEventListener("click", this.onDocumentClick);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.onDocumentClick);
  }
};
</script>




<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Halant:wght@300&family=Josefin+Sans:wght@500&family=Raleway:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@400;500&family=Noto+Sans+Tamil&display=swap");

.sticky-top{
  position: sticky;
  z-index: 999999;
  height: 60px;
}
.topbar {
  background: #f9ffed;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.09);
  font-family: "Mukta", sans-serif;
}

.bar h2 {
  font-size: 20px;
  color: #71a600;
}

.logo img {
  height: 40px;
  margin-right: 0.5rem;
}

.logo {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.nav-links {
  display: flex;
  list-style: none;
}

.nav-links li {
  margin: 0 0px;
  padding-top: 15px;
  font-size: 14px;
}

.nav-links li a {
  text-decoration: none;
  text-transform: uppercase;
}

a {
  color: #252525;
}

.nav-links li a:hover {
  color: #71a600;
  text-decoration: none;
}

.nav-links li a {
  text-decoration: none;
}

.active-link {
  color: #71a600;
  font-weight: 600;
  border-bottom: 3px solid #71a600;
}

.language-select {
  border: 1px solid rgb(0, 0, 0);
  border-radius: 5px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  padding: 0px 5px 5px 5px;
  color: #4e7300;
}

.language-select option {
  padding: 5px;
}

.language-select:focus {
  outline: none;
}

select.town {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("../src/assets/img/Icons/arrow_drop.png");
  background-size: 26px 26px;
  background-repeat: no-repeat;
  background-position: calc(100% - 0px) center;
  padding: 0px 30px 0px 5px;
  border: none;
  width: auto;
}

select.town:focus {
  border-color: rgb(255, 255, 255);
}

input[type="search"]::-webkit-search-cancel-button {
            -webkit-appearance: none;
            display: inline-block;
    
            width: 12px;
            height: 12px;
            font-size: 12px;
            margin-left: 10px;
            background:
                linear-gradient(45deg, rgba(8, 255, 65, 0) 0%,rgba(130, 216, 30, 0) 43%,#7bb13f 45%,#7bb13f 55%,rgba(52, 153, 26, 0) 57%,rgba(50, 195, 31, 0) 100%),
                linear-gradient(135deg, transparent 0%,transparent 43%,#87e709 45%,#7bb13f 55%,transparent 57%,transparent 100%);
        }

        input[type="search"][value="dark"]::-webkit-search-cancel-button {
            background:
                linear-gradient(45deg, rgba(123,177,63) 0%,rgba(123,177,63) 43%,#fff 45%,#fff 55%,rgba(123,177,63) 57%,rgba(123,177,63) 100%),
                linear-gradient(135deg, transparent 0%,transparent 43%,#fff 45%,#fff 55%,transparent 57%,transparent 100%);
        }

@media screen and (min-width: 1920px) {
  .nav-links li a {
    padding: 0px 30px;
  }
}
@media (min-width: 1800px) {
  
.searchproduct{
  width: 60%;
}

.locationhead {
  width: 30% !important;
}

.productcontainer {
  width: 60% !important;
}



}
@media screen and (min-width: 1620px)  {
  .nav-links li a {
    padding: 0px 20px;
  }
}
@media screen and (min-width: 1420px)  {
  .nav-links li a {
    padding: 0px 15px;
  }
}
@media screen and (min-width: 1200px)  {
  .nav-links li a {
    padding: 0px 5px;
  }
}

@media screen and (min-width: 992px)  {
  .sticky-top{
  position: sticky;
  z-index: 999999;
  height: 60px !important;
}
}
@media screen and (max-width: 991px) {

}


.searchproduct {
  padding: 0px 0px;
  width: 100%;
}

.search-bar {
  align-items: center;
  display: flex;
  width: 100%;
}

.input-container {
  position: relative;
}

.location {
  border-left: none;
  background: #f9ffed;
}

.locationicon {
  border: 1px solid #71a600;
  border-radius: 50px 0px 0px 50px;
  border-right: none;
  background: #f9ffed;
}

.locationhead {
  width: 50%;
}

.locationhead .locationcontainer {
  width: 100%;
}

.locationcontainer span {
  background: #f9ffed;
}

.searchhead {
  width: 100%;
}

.productcontainer {
  width: 100%;
}

.productcontainer input {
  border-right: none;
  background: #f9ffed;
}

.productcontainer span {
  border-left: none;
  background: #f9ffed;
}

.searchhead button {
  width: 8%;
  border-left: none;
}

.search-input {
  padding: 8px;
  border: 1px solid #71a600;
  border-left: none;
   font-size: 14px;
}

.search-results {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  min-width: 200px;
  color: #16123f;
  background: #f9ffed;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  max-height: 250px;
  overflow-y: auto;
  z-index: 9999;
  margin: 0px 0px;
}

.search-results li {
  cursor: pointer;
  padding: 3px 10px;
  font-size: 14px;
}

.search-results li:hover {
  cursor: pointer;
  color: #71a600;
  background: #fff;
}

.search-results::-webkit-scrollbar {
  width: 7px;
}

.search-results::-webkit-scrollbar-thumb {
  background-color: #71a600;
  border-radius: 5px;
}

.search-results::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.submit-button {
  font-size: 14px;
  color: #eaf3d7;
  border: 1px solid #71a600;
  border-radius: 0px;
  cursor: pointer;
  padding-top: 8px;
  padding-right: 1px;
  padding-left: 10px;
  border-left: none;
  border-radius: 0px 50px 50px 0px;
}

.submit-button-pro {
  padding: 6px 20px;
  border-radius: 35px;
  background: #71a600;
}

.submit-button:disabled {
  background-color: #ffffff;
  color: #52b788;
  cursor: not-allowed;
}

.location::placeholder {
  color: #71a600;
  font-weight: 500;
  font-size: 14px;
}

.product::placeholder {
  color: #b7b7b7;
  font-weight: 400;
  font-size: 14px;
}

input:focus {
  outline: none;
}

@media (max-width: 993px) {
  .language-dropdown {
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .searchproduct {
    padding: 0px 0px;
    width: 100%;
  }

  .search-bar {
    align-items: center;
    display: flex;
    width: 100%;
  }

  .input-container {
    position: relative;
  }

  .locationcontainer {
    width: 100%;
  }

  .productcontainer {
    width: 100%;
  }
}
@media (max-width: 668px) {
  .search-bar {
    align-items: center;
    display: flex;
    width: 100%;
  }

}
@media (min-width: 320px) and (max-width: 660px) {

  .search-bar {
    align-items: center;
    display: block;
    width: 100%;
  }
  .product {
    width: 100%;
    
  }
  .search-input{
    width: 100%;
    border-radius: 0px 50px 50px 0px;
  }
 .searchhead{
  margin-top: 3%;
 }

.search-results {
background: rgb(255, 255, 255);
}
 .search-results span {
  background: #ffffff;
}
  .searchhead .search-input{
    border-radius: 50px 50px 50px 50px;
      margin-bottom: 4% !important;

  }
.locationhead {
  width: 100% !important;
}

.searchhead .submit-button{
  padding: 0%;
  border: none;
  text-align: center !important;
  margin: 25%;
  }
.searchhead .submit-button .submit-button-pro {
  padding: 6px 60px;
 
}

}

.navbar-toggler button {
  background: rgb(139, 211, 139);
}

.navbar-toggler:focus {
  outline: none;
  border: none;
}

.dropdown>a {
  display: flex;
  align-items: center;
}

.dropdown:hover .dropdown-nav {
  width: 200px;
  opacity: 1;
  transform: translateY(0);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 1px 4px -1px rgba(0, 0, 0, 0.06);
}

.dropdown-nav {
  width: 200px;
  opacity: 0;
  transition: transform 0.3s cubic-bezier(0.88, -0.72, 0, 1),
    opacity 0.3s ease-in-out;
  transform: translateY(-4rem);
  list-style: none;
  padding: 0 10px;
  position: absolute;
  background: #eaf3d7;
}

.dropdown-nav a {
  font-weight: 300;
  padding: 0px 0px;
  width: 100%;
}

a {
  position: relative;
  z-index: 2;
  display: block;
  text-decoration: none;
  transition: 0.2s;
}

.tog {
  display: none;
}




@media (max-width: 993px) {
  .language-dropdown {
    margin-bottom: 10px;
  }

  .tog {
    display: block;
  }


  .dropdown-nav a{
    color:#fff;
    font-size: 22px;

  }

  .dropdown-nav {
    width: 200px;
    background: #d4d7c2;
    z-index: 0;
  }
  .dropdown-nav a:hover{
    color:#035e09;
    font-size: 22px;

  }
  legend,
label,
input,
ul,
li {
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
}

.input-toggler {
  display: none;
}

.menu-toggler {
  position: absolute;
  right: 10px;
  top: 0px;
  width: 50px;
  height: 70px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.menu-toggler-line {
  width: 50%;
  height: 3px;
  background: #ffcf27;
  margin: 0 0 6px 0px;
  position: relative;
  transition: all 0.4s ease-out;
}

.input-toggler:checked ~ .menu-toggler .menu-toggler-line {
 
   top: 6px;
  translatey : 90;
  transform: rotate(45deg);
}


.input-toggler:checked~.menu-toggler .menu-toggler-line:nth-child(2) {
  display: none;
}

.input-toggler:checked~.menu-toggler .menu-toggler-line:nth-child(3) {
   top: -3px;
  translatey : 30;
  transform: rotate(135deg); 

}

.nav {
  width: 100%;
  height: 100%;
  background: #d4d7c2;
}

.nav ul {
  width: 100%;
}

.nav ul li {
  width: 100%;
  text-align: center;
}

.nav ul li a {
  display: inline-block;
  font-size: 25px;
  color: #fff;
  text-transform: uppercase;
}

.sidebar {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100%;
  background: #f9ffed;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(-100%);
  transition: all 0.4s ease-out;
}

.menu-link {
  color: #71a600;
  font-size: 5vmin;
  line-height: 7vmin;
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  text-transform: uppercase;
  padding: 10px 0px;
}
 .language-select{
  color: #71a600;
  font-size: 4.5vmin;
  line-height: 7vmin;
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  text-transform: uppercase;
  padding: 0px 0px;
}
label{
  color: #ffcf27;
  font-size: 4.5vmin;
  line-height: 7vmin;
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  text-transform: uppercase;
  padding: 0px 0px; 
}
.menu-link:hover,
.menu-link:focus,
.menu-link:active {
  color: Gold;
}

.input-toggler:checked~.sidebar {
  transform: translateX(0%);
  opacity: 0.98;
}
.input-toggler:checked~.sidebar {
  transform: translateX(0%);
  opacity: 0.98;
}

}
.clear-button {
  cursor: pointer;
  background: none;
  border: none;
  font-size: .7rem;
  background: #71a600;
  border-radius: 50%;
  color: #fff; 
  position: absolute;
  z-index: 999;
  right: 10px;
  height: 20px;
  width: 20px;
}


@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Mulish&family=Work+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Asap+Condensed&family=Montserrat&family=Mulish&family=Work+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@400;500&family=Noto+Sans+Tamil&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@400;500&family=Noto+Sans+Tamil&family=Satisfy&display=swap");

.head {
  color: #fff;
  font-weight: 400;
  letter-spacing: -1px;
  font-family: "Mukta";
  position: absolute;
  z-index: 99;
  margin-top: -15px;
  left: 35px;
}
.normal-font {
  font-size: 16px;
}

.tamil-font {
  font-size: 14px; 
}
.marquee {
  position: relative;
  font-family: "Mukta";
  white-space: nowrap;
  overflow: hidden;
  height: 40px;
  margin: 0 auto;
  padding: 0.3rem 0 0.3rem 0;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}
.rectangle{
  position: absolute;
z-index: 9;
margin-top: -10px;
}
.design{
  position: absolute;
z-index: 99;
margin-top: -23px;
left: 10px;
}
.rectangle img{
  height: 80px;
}
.marquee_text {
  display: inline-block;
  padding-left: 100%;
  padding-top: 3px;
  text-transform: uppercase;
  color: aliceblue;
  animation: marquee-right-left 20s linear infinite;
}

.material-symbols-outlined {
  font-size: 18px;
  margin-top: -5px;
}

.marquee_text:hover {
  animation-play-state: paused;
}

.marquee_text:hover + .marquee_text {
  animation-play-state: paused;
}

.marquee_text:not(:hover) {
  animation-play-state: running;
}

@keyframes marquee-right-left {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.color:nth-child(odd) {
  color: #F71E01;
  font-family: "Mukta";
  font-size: 18px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
}

.color:nth-child(even) {
  color: #F71E01;
  font-family: "Mukta";
  font-size: 18px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
}
@media (min-width: 1800px) {
  .design{
    position: absolute;
  z-index: 99;
  margin-top: -23px;
  left: 200px;
  }
  .head {
    color: #fff;
    font-weight: 400;
    letter-spacing: -1px;
    font-family: "Mukta";
    position: absolute;
    z-index: 99;
    margin-top: -15px;
    left: 235px;
  }
  }
@media (min-width: 300px) and (max-width: 768px) {

  .rectangle img{
    height: 80px;
    width: 83%;
  }
.head{
  font-size: 13px;
  margin-top: -0px;
  margin-right: 5px;
}

}
.no-scroll {
  overflow: hidden;
  position: fixed;
  width: 100%;
  }
</style>