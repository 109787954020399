import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../components/HomePage.vue";
import AboutPage from "../components/AboutPage.vue";
import ErrorPage from "../components/ErrorPage.vue";
import ContactPage from "../components/ContactPage.vue";
import LoginAccount from "../components/homeaddon/LoginAccount.vue";
import AccountDelete from "../components/homeaddon/AccountDelete.vue";
import ProductDetails from "../components/ProductDetails.vue";
import PriceMarketPage from "../components/PriceMarketPage.vue";
import PricePage from "../components/PricePage.vue";
import RecommendPage from "../components/RecommendPage.vue";
import EquipDetails from "../components/EquipDetails.vue";
import BlogList from "../components/BlogList.vue";
import OffersPage from "../components/homeaddon/OffersPage.vue";
import PriceSublist from "../components/PriceSublist.vue";
import HomeBlog from "../components/HomeBlog.vue";
import FooterPage from "../components/FooterPage.vue";
import PrivacyPolicy from "../components/terms/PrivacyPolicy.vue";
import TermsConditions from "../components/terms/TermsConditions.vue";
import HomeBlogs from "../components/homeaddon/HomeMarket.vue";
import AdVayal from "../components/homeaddon/AdVayal.vue";
import PopUp from "../components/homeaddon/PopUp.vue";
import AllRecent from "../components/homeaddon/AllRecent.vue";
import AllList from "../components/AllList.vue";
import BottomAd from "../components/homeaddon/BottomAd.vue";
// import MarketPrice from '../components/marketprices/MarketPrice.vue';
import FarmPage from "../components/marketprice/FarmPage.vue";
import FarmPagefilter from "../components/marketprice/FarmPagefilter.vue";
import EquipmentChoice from "../components/marketprice/EquipmentChoice.vue";
import ProductChoice from "../components/marketprice/ProductChoice.vue";
import MarketSub from "../components/marketprices/MarketSub.vue";
import axios from "axios";
import metaDataJson from "../../src/meta.json"; 
const updateMetaTags = (title, description) => {
    // Update the document title
    // document.title = title;
    const metaTitle = document.querySelector('meta[name="title"]');
    if (metaTitle) {
        metaTitle.setAttribute("content", title);
    } else {
      const metaTag = document.createElement("meta");
      metaTag.setAttribute("name", "title");
      metaTag.setAttribute("content", title);
      document.head.appendChild(metaTag);
    }
    // Update the meta description tag
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", description);
    } else {
      const metaTag = document.createElement("meta");
      metaTag.setAttribute("name", "description");
      metaTag.setAttribute("content", description);
      document.head.appendChild(metaTag);
    }
  
    const metaOgTitle = document.querySelector('meta[property="og:title"]');
              if (metaOgTitle) {
                metaOgTitle.setAttribute("content", title);
              }
    
              const metaOgDescription = document.querySelector('meta[property="og:description"]');
              if (metaOgDescription) {
                metaOgDescription.setAttribute("content", description);
              }
    // Push updated meta data to GTM dataLayer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "metaUpdate",
      pageTitle: title,
      pageDescription: description,
    });
  };
  
  const routes = [
    {
      path: "/:location?",
      name: "Home",
      component: HomePage,
      meta: {
        title: "VayalAgro", // Default title
      },
      beforeEnter: (to, from, next) => {
        const homeMeta = metaDataJson.homepage.find((item) => item.pagename === "home");
  
        if (homeMeta) {
          updateMetaTags(homeMeta.title, homeMeta.description);
        }
        next();
      },
    },
    {
        path: "/market-price",
        name: "marketPrice",
        component: PriceSublist,
        props: true,
        meta: {
            title: "VayalAgro | MarketPrice", // Placeholder title
        },
        beforeEnter: async (to, from, next) => {
            const marketMeta = metaDataJson.marketpage.find((item) => item.pagename === "marketPrice");
      
            if (marketMeta) {
              updateMetaTags(marketMeta.title, marketMeta.description);
            }
            next();
        },
    },
    {
        path: "/market-price/:categoryName-price-today-:categoryId",
        name: "MarketSub",
        component: MarketSub,
        props: true,
        meta: {
            title: "Market Page", // Placeholder title
            metaTags: [
                {
                    name: "description",
                    content:
                        "Daily markets price, farm products, farm equipment, meat, vegetables, cattle, goat, Tamil Nadu agriculture",
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            const categoryName = to.params.categoryName;

            // Dynamically update the title and meta tags
            if (categoryName) {
                to.meta.title = `Market Page ${categoryName}`;
                document.title = to.meta.title; // Update document title
                to.meta.metaTags[0].name = categoryName;

                // Update meta tag in the document head
                const metaDescription = document.querySelector(
                    'meta[name="description"]'
                );
                if (metaDescription) {
                    metaDescription.setAttribute(
                        "content",
                        `Market prices for ${categoryName}`
                    );
                }
            }

            next();
        },
    },
    {
        path: "/homeblog",
        name: "HomeBlog",
        component: HomeBlog,
    },
    {
        path: "/recomend",
        name: "RecommendPage",
        component: RecommendPage,
    },
    {
        path: "/homeblogs",
        name: "HomeBlogs",
        component: HomeBlogs,
    },
    {
        path: "/privacy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
        meta: {
            title: "VayalAgro | PrivacyPolicy", // Placeholder title
        },
    },
    {
        path: "/terms",
        name: "TermsConditions",
        component: TermsConditions,
        meta: {
            title: "VayalAgro | TermsConditions", // Placeholder title
        },
    },

    {
        path: "/advayal",
        name: "AdVayal",
        component: AdVayal,
    },
    {
        path: "/blogs",
        name: "BlogList",
        component: BlogList,
        meta: {
            title: "VayalAgro | Blogs",
            
        },
        beforeEnter: async (to, from, next) => {
            const blogMeta = metaDataJson.blogspage.find((item) => item.pagename === "BlogList");
      
            if (blogMeta) {
              updateMetaTags(blogMeta.title, blogMeta.description);
            }
            next();
        },
    },
    {
        path: "/about",
        name: "About",
        component: AboutPage,
        meta: {
            title: "VayalAgro | About Us",
        },
        beforeEnter: async (to, from, next) => {
            const aboutMeta = metaDataJson.aboutpage.find((item) => item.pagename === "About");
      
            if (aboutMeta) {
              updateMetaTags(aboutMeta.title, aboutMeta.description);
            }
            next();
        },
    },
    {
        path: "/error",
        name: "Error",
        component: ErrorPage,
        meta: {
            title: "404 Error",
            metaTags: [{ name: "Error", content: "vayal Error." }],
        },
    },
    {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: ErrorPage,
    },
    {
        path: "/footer",
        name: "Footer",
        component: FooterPage,
    },
    {
        path: "/contact",
        name: "Contact",
        component: ContactPage,
        meta: {
            title: "VayalAgro | Contact",
        },
        beforeEnter: async (to, from, next) => {
            const contactMeta = metaDataJson.contactpage.find((item) => item.pagename === "Contact");
      
            if (contactMeta) {
              updateMetaTags(contactMeta.title, contactMeta.description);
            }
            next();
        },
    },

    {
        path: "/pricepages", // Modify the path as per your requirement
        name: "pricemarketpage",
        component: PricePage,
        beforeEnter(to, from, next) {
            // Fetch saved data from local storage
            const savedMarket = localStorage.getItem("selectedMarket");
            const savedPlace = localStorage.getItem("selectedPlace");

            // Pass saved data to the component as props
            to.params.savedMarket = savedMarket || "";
            to.params.savedPlace = savedPlace || "0";

            next();
        },
    },
    {
        path: "/recentlist",
        name: "recentlist",
        component: PriceMarketPage,
    },

   {
        path: "/market-price/tamilnadu/:categoryName-market-price:location?/:city?",
        name: "pricesublist",
        component: PriceSublist,
        props: true,
        meta: {
            title: "pricesublist", // Placeholder title
           
        },
        beforeEnter: async (to, from, next) => {
            const categoryName = to.params.categoryName;
            const pageName = "pricesublist"; // Page name as per your requirement
            const city = to.params.city;
            const district = to.params.location;
            const capitalizeFirstLetter = (string) => {
                return string.charAt(0).toUpperCase() + string.slice(1);
            };

            // Capitalize city and district
            const capitalizedCity = capitalizeFirstLetter(city || "");
            const capitalizedDistrict = capitalizeFirstLetter(district || "");
            try {
                // Fetch meta data from the API
                const response = await axios.get(
                    "https://vaiyal-app.herokuapp.com/meta",
                    {
                        params: {
                            category_name: categoryName,
                            page_name: pageName,
                        },
                    }
                );

                const metaData = response.data.data;

                if (metaData) {
                    // Update the title
                    const title = metaData.title
                        .replace("{city}", this.$route.params.city?capitalizedCity + "," : "")
                        .replace("{district}", capitalizedDistrict);
                    to.meta.title = title;
                    document.title = to.meta.title; // Update document title

                    // Update the description
                    const description = metaData.description
                        .replace("{city}", this.$route.params.city?capitalizedCity + "," : "")
                        .replace("{district}", capitalizedDistrict)
                    to.meta.metaTags[0].content = description;

                    // Update meta tags in the document head
                    const metaDescription = document.querySelector(
                        'meta[name="description"]'
                    );
                    if (metaDescription) {
                        metaDescription.setAttribute("content", description);
                    }
                    const head = metaData.title
                        .replace("{city}", this.$route.params.city?capitalizedCity + "," : "")
                        .replace("{district}", capitalizedDistrict)
                    to.meta.metaTags[0].content = head;
                    const metaHead = document.querySelector(
                        'meta[name="title"]'
                    );
                    if (metaHead) {
                        metaHead.setAttribute("content", head);
                    }
                }
            } catch (error) {
                console.warn("Error fetching meta data:", error);
            }

            next();
        },
    },
    {
        path: "/market-price/tamilnadu/:categoryName-market-price:location?/:city?/:created?",
        name: "pricesublist1",
        component: PriceSublist,
        props: true,
        meta: {
          title: "pricesublist1", // Placeholder title
         
      },
      beforeEnter: async (to, from, next) => {
        const categoryName = to.params.categoryName;
        const pageName = "pricesublist1"; // Page name as per your requirement
        const city = to.params.city;
        const district = to.params.location;
        const created = to.params.created;
        const capitalizeFirstLetter = (string) => {
            return string.charAt(0).toUpperCase() + string.slice(1);
        };
    
        // Capitalize city and district
        const capitalizedCity = capitalizeFirstLetter(city || "");
        const capitalizedDistrict = capitalizeFirstLetter(district || "");
    
        try {
            // Fetch meta data from the API
            const response = await axios.get(
                `https://vaiyal-app.herokuapp.com/meta`,
                {
                    params: {
                        category_name: categoryName,
                        page_name: pageName,
                    },
                }
            );
    
            const metaData = response.data.data;
    
            if (metaData) {
                // Update the title
                const title = metaData.title
                    .replace("{city}", to.params.city ? capitalizedCity + ", " : "")
                    .replace("{district}", capitalizedDistrict);
                to.meta.title = title;
                document.title = to.meta.title; // Update document title
    
                // Update the description
                const description = metaData.description
                    .replace("{city}", to.params.city ? capitalizedCity + ", " : "")
                    .replace("{district}", capitalizedDistrict)
                    .replace("{date}", created);
                to.meta.metaTags[0].content = description;
    
                // Update meta tags in the document head
                const metaDescription = document.querySelector(
                    'meta[name="description"]'
                );
                if (metaDescription) {
                    metaDescription.setAttribute("content", description);
                }
    
                // Add or update the canonical link
                const canonicalLink = document.querySelector('link[rel="canonical"]');
                const canonicalHref = `https://vayalagro.com/market-price/${categoryName}`;
                if (canonicalLink) {
                    canonicalLink.setAttribute("href", canonicalHref);
                } else {
                    const linkElement = document.createElement("link");
                    linkElement.setAttribute("rel", "canonical");
                    linkElement.setAttribute("href", canonicalHref);
                    document.head.appendChild(linkElement);
                }
            }
        } catch (error) {
            console.warn("Error fetching meta data:", error);
        }
    
        next();
    }
    
    },

    {
        path: "/market-price/tamilnadu/:categoryName-market-price:location?/:city?/:name-history-price",
        // path: "/tamilnadu/andhra-rose-history-price-erode/gobi",
        // '/market-price/tamilnadu/:categoryName-market-price:location?/:city?'
        // path: "/historyprice_of_:name?/:categoryname/:categoryType/:categoryId?/:subCategoryId?-:marketId-:marketPlaceId",
        name: "AllRecent",
        component: AllRecent,
        meta: {
            title: "VayalAgro | Recent Price", // Placeholder title
          },
          beforeEnter: async (to, from, next) => {
              const categoryName = to.params.categoryName.replace(/-/g,' ');
              const subName = to.params.name.replace(/-/g,' ');
              const district = to.params.location.replace(/-/g,' ') || "Tamilnadu"; // Default location if none is provided
              const city = to.params.city || "Tamilnadu";
              const capitalizeFirstLetter = (string) => {
                return string.charAt(0).toUpperCase() + string.slice(1);
            };

            // Capitalize city and district
            const capitalizedCate = capitalizeFirstLetter(categoryName || "");
            const capitalizedSubCate = capitalizeFirstLetter(subName || "");
            const capitalizedCity = capitalizeFirstLetter(city || "");
            const capitalizedDistrict = capitalizeFirstLetter(district || "");
              try {
                // Fetch the JSON file (or this could be an API endpoint)
                // const response = await axios.get('/meta.json');
               
                const historyMeta = metaDataJson.historypage.find((item) => item.pagename === "AllRecent");

                if (historyMeta) {
                  // Replace {district} with the actual location (city)
                  const title = historyMeta.title.replace("{city}", capitalizedCity).replace("{district}", capitalizedDistrict.trim()).replace("{Category}", capitalizedCate);
                  const description = historyMeta.description.replace("{city}", capitalizedCity).replace("{district}", capitalizedDistrict.trim()).replace("{Category}", capitalizedCate).replace("{subcategory}", capitalizedSubCate);
        
                  // Update the document title
                //   document.title = title;
                const metaTitle = document.querySelector('meta[name="title"]');
                if (metaTitle) {
                    metaTitle.setAttribute("content", title);
                }
                  // Update the meta description tag
                  const metaDescription = document.querySelector('meta[name="description"]');
                  if (metaDescription) {
                    metaDescription.setAttribute("content", description);
                  }
        
                  // Push the updated meta data to the GTM dataLayer
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    event: "metaUpdate",
                    pageTitle: title,
                    pageDescription: description,
                  });
                }
              } catch (error) {
                console.error("Error fetching meta data:", error);
              }
        
              next();
            },
    },
    {
        path: "/historyprice_of_:categoryname/:categoryType/:categoryId-:marketId-:marketPlaceId",
        name: "AllRecentD",
        component: AllRecent,
        meta: {
            title: "VayalAgro | Recent Price",
            metaTags: [
                {
                    name: "VayalAgro-Recent1",
                    content:
                        "Daily markets price, farm products, farm equipment, meat, vegetables, cattles, goat, Tamil Nadu agriculture",
                },
            ],
        },
    },
    {
        path: "/:location/:category/:categoryName?/:product-list/vap:id",
        name: "resultproduct",
        component: ProductDetails,
        beforeEnter: (to, from, next) => {
            const { id } = to.params;
            if (!/^\d+$/.test(id)) {
              console.warn("Invalid ID in URL, redirecting to error page.");
              next({ name: "Error" });
            } else {
              next(); // Allow navigation
            }
          },
    },
    {
        path: "/:location/:category/:categoryName?/:product-list/vls:id",
        name: "livestockdetails",
        component: ProductDetails,
        beforeEnter: (to, from, next) => {
            const { id } = to.params;
            if (!/^\d+$/.test(id)) {
              console.warn("Invalid ID in URL, redirecting to error page.");
              next({ name: "Error" });
            } else {
              next(); // Allow navigation
            }
          },
    },
    {
        path: "/:location/:category/:categoryName?/:product-list/vae:id",
        name: "equipment",
        component: EquipDetails,
        beforeEnter: (to, from, next) => {
            const { id } = to.params;
            if (!/^\d+$/.test(id)) {
              console.warn("Invalid ID in URL, redirecting to error page.");
              next({ name: "Error" });
            } else {
              next(); // Allow navigation
            }
          },
    },
    {
        path: "/offers",
        name: "OffersPage",
        component: OffersPage,
    },
    {
        path: "/popup",
        name: "PopUp",
        component: PopUp,
    },

    {
        path: "/:location/:category/:categoryName?/:product-list/:city?", // subcat as product. need to handle DC
        name: "AllList",
        component: AllList,
        props: true,
    },

    {
        path: "/:location/:category",
        name: "FarmPage",
        component: FarmPage,
        props: true,
        meta: {
            title: "FarmPage", 
          },
          beforeEnter: async (to, from, next) => {
            const categoryType = to.params.category;
            const district = to.params.location.trim() || "Tamilnadu"; 
            const capitalizeFirstLetter = (string) => {
                return string.charAt(0).toUpperCase() + string.slice(1);
            };

            // Capitalize city and district
           
            const capitalizedDistrict = capitalizeFirstLetter(district || "");
            try {
                const farmMeta = metaDataJson.farmpage.find(
                    (item) => item.category_type === categoryType
                );
        
                if (farmMeta) {
                    const title = farmMeta.title.replace("{district}", capitalizedDistrict);
                    const description = farmMeta.description.replace("{district}", capitalizedDistrict);
        
                    // Dynamically update meta tags
                    document.title = title;
                    const metaTitle = document.querySelector('meta[name="title"]');
                    if (metaTitle) {
                        metaTitle.setAttribute("content", title);
                    }
                    const metaDescription = document.querySelector('meta[name="description"]');
                    if (metaDescription) {
                        metaDescription.setAttribute("content", description);
                    }
                    const metaOgTitle = document.querySelector('meta[property="og:title"]');
                    if (metaOgTitle) {
                      metaOgTitle.setAttribute("content", title);
                    }
          
                    const metaOgDescription = document.querySelector('meta[property="og:description"]');
                    if (metaOgDescription) {
                      metaOgDescription.setAttribute("content", description);
                    }
                    // Push meta data to GTM dataLayer
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: "metaUpdate",
                        pageTitle: title,
                        pageDescription: description,
                    });
                } else {
                    console.warn("No metadata found for category:", categoryType);
                }
            } catch (error) {
                console.error("Error updating meta data:", error);
            }
        
            next();
        },
    },
    {
        path: "/:location/:category/:categoryName",
        name: "FarmPagefilter",
        component: FarmPagefilter,
        meta: {
          title: "FarmPagefilter", // Placeholder title
        },
        beforeEnter: async (to, from, next) => {
          const categoryName = to.params.categoryName;
          const city = to.params.location || "Tamilnadu"; // Default location if none is provided
    
          try {
            // Fetch the JSON file (or this could be an API endpoint)
            // const response = await axios.get('/meta.json');
            const metaData = metaDataJson.data.farmpagefilter.find(
              (item) => item.category_name === categoryName
            );
    
            if (metaData) {
              // Replace {district} with the actual location (city)
              const title = metaData.title.replace("{district}", city);
              const description = metaData.description.replace("{district}", city);
    
              // Update the document title
              document.title = title;
    

              // Update the meta description tag
              const metaDescription = document.querySelector('meta[name="description"]');
              if (metaDescription) {
                metaDescription.setAttribute("content", description);
              }

              const metaOgTitle = document.querySelector('meta[property="og:title"]');
              if (metaOgTitle) {
                metaOgTitle.setAttribute("content", title);
              }
    
              const metaOgDescription = document.querySelector('meta[property="og:description"]');
              if (metaOgDescription) {
                metaOgDescription.setAttribute("content", description);
              }
              // Push the updated meta data to the GTM dataLayer
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: "metaUpdate",
                pageTitle: title,
                pageDescription: description,
              });
            }
          } catch (error) {
            console.error("Error fetching meta data:", error);
          }
    
          next();
        },
      },
    {
        path: "/ad",
        name: "BottomAd",
        component: BottomAd,
    },
    {
        path: "/VayalAgro-choice_agriculture-equipments",
        name: "EquipmentChoice",
        component: EquipmentChoice,
    },
    {
        path: "/VayalAgro-choice_agriculture-products",
        name: "ProductChoice",
        component: ProductChoice,
    },
    {
        path: "/login",
        name: "LoginAccount",
        component: LoginAccount,
        meta: {
            title: "VayalAgro | Login",
            metaTags: [{ name: "Login", content: "vayal Login." }],
        },
    },
    {
        path: "/delete-account",
        name: "AccountDelete",
        component: AccountDelete,
        meta: {
            title: "VayalAgro | AccountDelete",
            metaTags: [
                { name: "AccountDelete", content: "vayal AccountDelete." },
            ],
        },
        beforeEnter: (to, from, next) => {
            if (to.query.token) {
                next();
            } else {
                next("/login");
            }
        },
    },
];

let preventScroll = true;

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (preventScroll) {
            preventScroll = false; // Reset the flag
            return false; // Maintain the current scroll position
        }

        if (savedPosition) {
            
            return savedPosition;
        } else if (to.hash) {
            const element = document.getElementById(to.hash.slice(1));
            if (element) {
                return { el: element, behavior: "smooth" };
            }
        } else {
            return { top: 0, behavior: "smooth" };
        }
    },
});

export function disableScroll() {
    preventScroll = true;
}

function validateParams(params) {
    const validRegex = /^[a-z0-9-&/]+$/; // Allows only lowercase, numbers, hyphens, & and /
  
    for (const key in params) {
      const value = params[key];
      if (!validRegex.test(value)) {
        console.error(`Invalid parameter: ${key}=${value}`);
        return false;
      }
    }
    return true;
  }
  
router.beforeEach((to, from, next) => {
  
    const newParams = { ...to.params };
    if (!validateParams(to.params)) {
        console.warn("Redirecting due to invalid parameters.");
        // next({ name: "Error" }); // Redirect to a safe route or 404 page
      } else {
        next();
      }
    if (
        to.params.market_name &&
        to.params.market_name !== to.params.market_name.toLowerCase()
    ) {
        newParams.market_name = to.params.market_name.toLowerCase();
    }

    if (
        to.params.location &&
        to.params.location !== to.params.location.toLowerCase()
    ) {
        newParams.location = to.params.location.toLowerCase();
    }

    if (
        to.params.product &&
        to.params.product !== to.params.product.toLowerCase()
    ) {
        newParams.product = to.params.product.toLowerCase();
    }
    if (JSON.stringify(newParams) !== JSON.stringify(to.params)) {
        next({
            name: to.name,
            params: newParams,
            query: to.query,
            hash: to.hash,
        });
    } else {
        next();
    }
});

export default router;
