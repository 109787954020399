<template>
    <div class="pagelink d-flex py-5">
      <div class="container-fluid pt-5 text-center">
        <h1 class="text-uppercase" v-if="this.$route.params.categoryName">
         {{ this.$route.params.categoryName.replace(/-/g, ' ') }}
        </h1>

        <h2 v-else></h2>
        <h6 class="text-uppercase" >
  <a href="/">Home</a>

  <i class="fa fa-angle-right ps-1 pe-2" aria-hidden="true"></i>

    <router-link :to="this.$route.params.category === 'agriculture-products' ? `/${this.$route.params.location}/agriculture-products` : this.$route.params.category === 'agriculture-equipments' ? `/${this.$route.params.location}/agriculture-equipments` : `/${this.$route.params.location}/livestocks`">
                  {{ this.$route.params.category === 'agriculture-products' ? translations.farmProducts : this.$route.params.category === 'agriculture-equipments' ? translations.farmEquipments : this.$route.params.category === 'livestocks' ? translations.Livestocks : this.$route.params.category }}
   </router-link>

</h6>

      </div>
    </div>
  <div class="text-break overflow-hidden full">

  <h2 class="text-center ">
      
  </h2>
  <div class="a-header d-md-none">
    <input type="checkbox" name="main-nav" id="main-nav" class="burger-check bg-dark" v-model="isSidebarOpen" />
    <label for="main-nav" class="burger menu w-100">
      <span></span>
    </label>
    <strong class="ms-5 ps-2">{{ translations.filer_category }}</strong>
    <ul class="px-1 py- mt-" :class="{ 'is-open': isSidebarOpen }">
      <li v-if="isLoading">
        <div class="col-md-12 m-0 p-0 pt-3">
       
        </div>
      </li>
      <li class="p-0 m-0" v-for="category in filterCategories" :key="category.category_id">
        <router-link
          @click="handleLinkClick"
          :to="{ name: 'FarmPagefilter', params: { categoryId: category.category_id, categoryName: category.category_name.replace(/\s/g, '-').toLowerCase(), location: $route.params.location } }"
        >
          <p class="p-0 m-0 my-">
            {{ selectedLanguage === "en" ? category.category_name : category.tn_name }} ({{ category.list }})
          </p>
        </router-link>
      </li>
    </ul>
  </div>
  
  
  <div class="row" v-if="loading">
    <div class="col-md-2 my-3">
      <div v-for="index in 5" :key="index" >
        <p class="shimmer-line"></p>
        <p class="shimmer-line"></p>
        <p class="shimmer-line"></p>
      </div>
    </div>   
    <div class="col-md-10 my-3">
    <div class="row">
      <div v-for="index in 8" :key="index" class="col-md-4 col-lg-3 my-3col-sm-2">
        <p class="shimmer-line " style="height:150px"></p>
        <p class="shimmer-line"></p>
        <p class="shimmer-line"></p>
      </div>
    </div>  
      </div>
            
</div>
    <div class="row" v-else>
      <div class="col-md-2 right d-md-block d-none d-md-block">
          <div class="filtercategory ms-3 py-4 three">
              <h2 class="three-cate">{{translations.getcate}}</h2>
              <ul class=" px-1 mt-4">
                  <li v-if="isLoading" >
                      <div class="col-md-12 m-0 p-0">
                          <div v-for="index in 12" :key="index" >
                            <p class="shimmer-line"></p>
                            <p class="shimmer-line"></p>
                            <p class="shimmer-line"></p>
                          </div>
                        </div>  
                  </li>
                <li v-for="category in filterCategories" :key="category.category_id">
                <router-link class="" :to="category.hasSubCategory
    ? { 
        name: 'FarmPagefilter', 
        params: { 
            categoryId: category.category_id, 
            categoryName: category.category_name ? category.category_name.replace(/\s/g, '-').toLowerCase(): '', 
            category: category.category_type == 'Live stocks'
                                ? 'livestocks'
                                : category.category_type
                                    .replace(/\s/g, '-')
                                    .replace('farm', 'agriculture'),
        } 
    }
    : { 
        name: 'AllList', 
        params: { 
          category: this.$route.params.category,
          product: category.category_name ? category.category_name.replace(/\s/g, '-'): '', 
          location
        }
    }"
    :title="`Cat: ${category.category_name}`"
  >
                          <img class="rounded farm-page-filer-page" :src="category.images[0]" loading="lazy" :alt="`Cat: ${category.category_name}`" />
                          <p class="p-0 my-"  :title="`Cat: ${category.category_name}`">
                              {{ selectedLanguage === 'en' ? category.category_name : category.tn_name }} ({{ category.list }})
                          </p>
                      </router-link>
                </li>
                
                
              </ul>
            </div>
      </div>
     <div class="col-md-10 left-con farm-page-filer-page">
  <!-- <div class="one" v-if="responseData && responseData.data && responseData.data.length > 0">
    <h2 class="text-center my-4 farm-page-filer-page">
      
       {{
                  selectedLanguage === "en"
                    ? responseData.category_name
                    : responseData.data.tn_name
                }}
    </h2>
  </div> -->

  <div v-if="isLoading" class="row">
    <div v-for="index in 12" :key="index" class="col-md-3 col-lg-3 col-sm-2">
      <p class="shimmer-line" style="height:150px"></p>
      <p class="shimmer-line"></p>
      <p class="shimmer-line"></p>
    </div>
  </div>

  <div v-else>
    <div v-if="responseData && responseData.data && responseData.data.length > 0" class="row mx-md-3 mx-1">
      <div
        class="col-md-6 col-sm-6 col-lg-3 mb-4 mt-3 p-2 text-center farm-page-filer-page"
        v-for="subCategory in responseData.data"
        :key="subCategory.sub_category_id"
      >
        <div class="sub-img py-2 farm-page-filer-page" :class="{'disabled-column': subCategory.list === '0'}">
          <img
            @click="navigateToAllList(subCategory.sub_category_name)"
            class="rounded farm-page-filer-page"
            :src="subCategory.images[0]"
            :alt="`Subcat: ${subCategory.sub_category_name}`"
          />
          <div class="sub-name text-md-center">
            <h3 class="pt-2 name m-0 pb-0" @click="navigateToAllList(subCategory.sub_category_name)" :title="`Subcat: ${subCategory.sub_category_name}`">
              {{
                selectedLanguage === "en" ? subCategory.sub_category_name : subCategory.tn_sub_name
              }}
              ({{ subCategory.list }})
            </h3>
            <!-- <p
              class="px-2 py-0 m-0 mt-1 text-capitalize history"
              @click="
                viewMore(
                  subCategory.sub_category_id,
                  responseData.category_type ? responseData.category_type.replace(/\s/g, '-').replace('farm', 'agriculture') : '',
                  subCategory.sub_category_name ? subCategory.sub_category_name.replace(/\s/g, '-') : '',
                  subCategory.market_place_id,
                  subCategory.market_id,
                  responseData.category_name ? responseData.category_name.replace(/\s/g, '-') : ''
                )
              "
            >
              {{ translations.history }}&nbsp;
              <i class="fas fa-chart-line"></i>
            </p> -->
          </div>
        </div>
        <div v-if="subCategory.list === '0'">
          <div class="popup">
            <p>No Product</p>
          </div>
        </div>
      </div>
    </div>
    <div v-else >
      <div v-if="loading">
            <div class="row MT-3">
              <div v-for="index in 12" :key="index" class="col-md-4 col-lg-3 col-sm-2">
                <p class="shimmer-line " style="height:150px"></p>
                <p class="shimmer-line"></p>
                <p class="shimmer-line"></p>
              </div>
            </div>
      </div>
      <div v-else class="justify-content-center text-center">
            <div class="nodata-img">
              <img class="not-found-img" src="../../assets/img/why/emptypro.webp" loading="lazy" alt="empty">
            </div>
              <h2 class="my-3 no-data">
                {{ translations.no_data_product }}  
               </h2>
               
               <p class="no-data-para">{{ translations.no_data_product_para }}</p>
            <!-- <h2 class="my-3 no-data">{{`${$route.params.product?.replaceAll('-',' ')} will be coming soon in ${this.$route.params?.city?.replaceAll('-',' ') || $route.params.location?.replaceAll('-',' ')}!`}} <br> </h2>-->
              <button class="mt-5 btns-soon my-2 px-4 py-2"><a href="/">Go Home</a> </button> 
            
          </div>
    </div>
  </div>

  <div v-html="responseData?.cat_more" :id="$style.more"></div>
</div>

    </div>
   
  </div>
</template>
<script>
import { en, ta } from "@/translations";
import "bootstrap/dist/css/bootstrap.css";
import axios from "axios";
import { useItemStore } from "@/stores/userStore";
import { mapActions, mapState } from "pinia";
import metaDataJson from "../../meta.json"; // Adjust the path based on where your JSON file is located

export default {
  name: "FarmPagefilter",
  props: ["selectedLanguage"],
  data() {
    return {
      isLoading: true,
      responseData: null,
      isSidebarOpen: false,
      filterCategories: [],
    };
  },
  created() {
    console.log('created called in farmpagefilter')
    if (this.$route.params.categoryName.includes('-list')) {
      const product = this.$route.params.categoryName.split('-list')[0]
      this.$route.params.categoryName = null;
      console.log('redirecting to list page...')
      this.$router.push({
        name: "AllList",
        params: {
          ...this.$route.params,
          product: product
        }
      });
    }
    this.getItemId();
    this.updateMetaTags(); // Initialize meta tags on component creation
  },
  computed: {
    // Map state from Pinia store to computed properties
    ...mapState(useItemStore, ['categoryId', 'subCategoryId']),
    translations() {
      return this.selectedLanguage === "en" ? en : ta;
    },
  },
  watch: {
    categoryId(newVal) {
      console.log('Category ID changed:', newVal);
      this.categoryId = newVal;
    },
    '$route.params': function() {
      this.getItemId();
      this.updateMetaTags(); // Update meta tags whenever route params change
    },
  },
  methods: {
    async getItemId() {
      const itemStore = useItemStore();
      await itemStore.updateItemId(this.$route.params.categoryName?.replace(/-/g,' '),'category_name');
      this.fetchData();
    },
    ...mapActions(useItemStore, ['updateItemId']),
    handleLinkClick() {
      this.closeSidebar();
    },
    closeSidebar() {
      this.isSidebarOpen = false;
    },
    async loadFilterCategories() {
  try {
    let apiUrl = 'https://vaiyal-app.herokuapp.com/categorytype';
    let requestBody = {
      categorytype: this.$route.params.category.replace(/-/g, ' ').replace('agriculture', 'farm')
    };

    if (this.$route.params.location !== 'tamilnadu') {
      apiUrl += `?marketname=${this.$route.params.location}`;
    }

    const response = await axios.post(apiUrl, requestBody, {
      headers: {
        version: '3.0'
      }
    });

    this.filterCategories = response.data.data;
    console.log("filterCategories", this.filterCategories);

    // if (!this.filterCategories || this.filterCategories.length === 0) {
    //   this.$router.replace({ path: "/error" });
    //   return; 
    // }

    // const CategoryError = this.filterCategories.map(item => item.category_type);

    // if (CategoryError.length === 0) {
    //   this.$router.replace({ path: "/error" });
    // }

  } catch (error) {
    console.error('Error fetching filter categories:', error);
    // this.$router.replace({ path: "/error" }); // Redirect to error page in case of any error
  }
}
,
    async fetchData() {
      try {
        this.loadFilterCategories();

        let apiUrl = "https://vaiyal-app.herokuapp.com/categoryweb";
        
        // Conditionally append marketname to apiUrl if location is not TamilNadu
        if (this.$route.params.location !== 'tamilnadu') {
          apiUrl += `?marketname=${this.$route.params.location}`;
        }

        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            categoryid: this.categoryId,
          }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        this.responseData = data;
        console.log("this.responseData",data.category_type)

         const apiCategoryType = data.category_type;
         console.log("apiCategoryType",apiCategoryType )
    const currentCategoryType = 
      this.$route.params.category === 'agriculture-products' ? 'Agriculture Products' :
      this.$route.params.category === 'agriculture-equipments' ? 'Agriculture Equipments' :
      this.$route.params.category === 'live stocks' ? 'Livestocks' :
      this.$route.params.category; 


      let needsRouteUpdate = false;
    let newRouteParams = { ...this.$route.params }; 

    if (apiCategoryType && apiCategoryType !== currentCategoryType) {
      newRouteParams.category = apiCategoryType === "live stocks" ? "livestocks" : apiCategoryType.replace("farm", "agriculture").replace(/\s+/g, '-').toLowerCase();
      console.log("newRouteParams.category",newRouteParams.category)
      needsRouteUpdate = true;
    }
      if (needsRouteUpdate) {
      this.$router.replace({ ...this.$route, params: newRouteParams });
    }
        this.isLoading = false;
        
      } catch (error) {
        console.error('Error fetching data:', error);
        this.isLoading = false;
      }
    },
    navigateToAllList(subcategoryName) {
      const location = this.$route.params.location; // Assuming location is available in your current route params
      this.$router.push({
        name: "AllList",
        params: {
          category: this.$route.params.category,
          categoryName: this.$route.params.categoryName,
          product: subcategoryName.replace(/\s+/g, "-"),
          location: location
        }
      });
    },
    viewMore(subCategoryId, categoryType, subCategoryName, marketPlaceId, marketId, categoryName) {
      // Redirect to the 'AllRecent' route with the provided parameters
      this.$router.push({
        name: "AllRecent",
        params: {
          subCategoryId: subCategoryId,
          categoryType: categoryType == 'live-stocks' ? 'livestocks' : categoryType.replace(/\s+/g, '-').replace('farm','agriculture'),
          name: subCategoryName,
          marketPlaceId: marketPlaceId || " ",
          marketId: marketId || " ",
          categoryname: categoryName
        }
      });
    },
  async updateMetaTags() {
  const categoryName = this.$route.params.categoryName;
 // Static page name, as per your structure
  const city = this.$route.params.location || "Tamilnadu";
  const created = this.$route.params.created;

  // Function to capitalize the first letter
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const capitalizedCity = capitalizeFirstLetter(city || '');

  try {
    // Find the metadata for the given category
    const metaData = metaDataJson.farmpagefilter.find(
      (item) => item.category_name === categoryName
    );

    if (metaData) {
      // Update the title
      const title = metaData.title.replace(/{district}/g, capitalizedCity);
      console.log("🚀 ~ updateMetaTags ~ title:", title);
      document.title = title;

      // Update the description
      const description = metaData.description
        .replace(/{district}/g, capitalizedCity)
        .replace("{date}", created); // In case you need to replace the created date
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute("content", description);
      }
       const metaOgTitle = document.querySelector('meta[property="og:title"]');
              if (metaOgTitle) {
                metaOgTitle.setAttribute("content", title);
              }
const metaOgDescription = document.querySelector('meta[property="og:description"]');
              if (metaOgDescription) {
                metaOgDescription.setAttribute("content", description);
              }
      // Update additional meta tags (e.g., title for SEO purposes)
      const head = metaData.title
        .replace("{city}", capitalizedCity ? `${capitalizedCity},` : "")
        .replace("{district}", capitalizedCity);
      const metaHead = document.querySelector('meta[name="title"]');
      if (metaHead) {
        metaHead.setAttribute("content", head);
      }
    } else {
      // this.$router.replace({path:"/error"})
      console.warn(`No metadata found for category_name: ${categoryName}`);
    }
  } catch (error) {
    console.warn("Error fetching meta data:", error);
  }
}
  },
};
</script>

<style scoped>
  @import url("https://fonts.googleapis.com/css2?family=Halant:wght@300&family=Josefin+Sans:wght@500&family=Raleway:wght@500&display=swap");
  @import url('https://fonts.googleapis.com/css2?family=Mukta:wght@400;500&family=Noto+Sans+Tamil&family=Satisfy&display=swap');
  .containers {
    overflow: hidden;
    font-family: "Mukta";
  }
  
  .left-con {
  }
  
  .innerleft {
    border-bottom: 2px solid rgba(0, 77, 0, 0.2);
  }
  
  .left-con img {
    width: 100%;
    height: 125px;
    border-radius: 6px;
  }
  
  h4 {
    font-size: 16px;
    text-align: center;
  
  }
  
  p {
    font-size: 12px;
  }
  
  .sublist {
    background: rgb(144, 217, 144);
  }
  
  .history,
  .name {
    cursor: pointer;
  }
  
  .shimmer-line {
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
    height: 15px;
    margin-bottom: 10px;
  }
  
  .shimmer-line {
    height: 20px;
    width: 100%;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    margin-bottom: 10px;
    border-radius: 10px;
    animation: shimmer 1s infinite linear;
  }
  
  img {
    object-fit: contain;
  }
  .big-img img{
    height:100px ;
    position: relative;
  }
  .big-img h4{

  font-size: 30px;
  color: #71a600;
  }
  .sub-name h2:hover{
    color: #71A600;
    font-weight: 600;
    }
    .sub-name p {
      color: rgb(1, 71, 1);
    }
   
  .sub-name p:hover{
  color: green;
  font-weight: 500;
  }
  .sub-name h2,h3{
  font-size: 16px;
     color: green;
}
  .sub-img{
  }
  .sub-img img{ 
    cursor: pointer;
    transition: transform .2s;
  }
  .sub-img:hover img{
    transform: scale(1.05); 
  }
  @media (min-width:1800px)  {
    .sub-img img {
      height: 160px;
  
  
    }
     .full{
  margin: 0 15%;
}
  }

  @media (min-width:300px) and (max-width:768px) {
    .sub-img{
      display: flex;
    }
    .sub-img img {
      width: 80px;
      height: 80px;
      border-radius: 6px;
      margin: 0px 10px 0px 10px;
  
    }
    .big-img h4{
      font-size: 20px;
      margin-top:5px ;
  
    }

    .sub-name{
    text-align: center !important;
    padding-top: 4%;
  }

  .pagelink{
      background-image: url("../../assets/img/drive/Detail-Banner-mbl.webp") !important;
  }
  }
  .show{
    box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
  
  }
  .show:hover{
    background: #000;
  }
  ul li {
    list-style-type: none;
    color: #71A600;
  }
  ul li a{
    list-style-type: none;
    color: #71A600;
  }
  .right{
    background: #F9FFED;
    box-shadow: 11px 0px 102px -2px rgba(249,255,237,1),
    -25px 0px 20px -20px rgba(0,0,0,0.45);
  }
  .filtercategory p {
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    border-bottom: 1px solid #adfbb9;
  }
  .filtercategory img{
    height: 50px ;
    width: 50px;
    background: #fdfdfd;
  }
  .shimmer {
    animation: shimmer 1s infinite linear;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
  }
  
  .shimmer-line {
    height: 20px;
    width: 100%;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    margin-bottom: 10px;
    border-radius: 10px;
    animation: shimmer 1s infinite linear;
  }
  .sub-img{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    transition: all 0.5s;
    border-radius: 10px;
  
  }
  .sub-img:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    }
    .three .three-cate {
      font-family: 'satisfy';
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 1.1em;
      padding-bottom: 15px;
      position: relative;
    }
    .three .three-cate:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 5px;
      width: 55px;
      background-color: #71A600;
    }
    .three .three-cate:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 2px;
      height: 1px;
      width: 95%;
      max-width: 255px;
      background-color: #71A600;
    }
    /* === HEADING STYLE #3 === */
.one h2 {
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5em;
  padding-bottom: 15px;
  position: relative;
}
.one h2:before {
  width: 28px;
  height: 5px;
  display: block;
  content: "";
  position: absolute;
  bottom: 13px;
  left: 50%;
  margin-left: -14px;
  background-color: #71A600;
}
.one h2:after {
  width: 100px;
  height: 1px;
  display: block;
  content: "";
  position: relative;
  left: 50%;
  margin-left: -50px;
  background-color: #71A600;
}


.disabled-column {
  pointer-events: none;  
  opacity: 0.6;     
  background-color: rgba(0, 0, 0, 0.1);  

      
}

.disabled-column:hover {
  pointer-events: auto;  
  opacity: 1;           
  background-color: rgba(0, 0, 0, 1);  

}
.popup {
  background-color: #fff;
  padding: 10px;
  border: 1px solid #ccc;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 35px;
  transform: translate(-50%, -50%);
  color: #1c6402;
  font-weight: 600;

}
strong{
  font-size: 24px;
  color:#1c6402 ;
}
@media (min-width:300px) and (max-width:767px) {


  *,
  *:before,
  *:after {
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      box-sizing: border-box;
  }
  
  .burger-check {
      display: none;
  }
  
  
  .burger {
      position: relative;
      float: left;
      margin-left: 10px;
      width: 60px;
      cursor: pointer;
  }
  
  .burger:after {
      content: '';
      position: absolute;
      bottom: 7px;
      left: 0px;
      width: 100%;
      text-align: center;
      font-size: 13px;
  }
  
  
  .burger span,
  .burger span:before,
  .burger span:after {
      position: absolute;
      left: 10px;
      top: 22px;
      width: 30px;
      height: 4px;
      background: #1C6402;
      border-radius: 0.2em;
  }
  
  
  .burger span:before,
  .burger span:after {
      content: '';
      left: 0;
  }
  
  .burger span:before {
      top: -8px;
  }
  
  .burger span:after {
      top: 8px;
  }
  
  .burger-check:checked + .burger:after {
      content: '';
  }
  
  .burger-check:checked + .burger span {
      height: 0;
      width: 0;
  }
  
  .burger-check:checked + .burger span:before,
  .burger-check:checked + .burger span:after {
      top: 2px;
  }
  
  .burger-check:checked + .burger span:before {
      -webkit-transform: rotate(225deg);
      -ms-transform: rotate(225deg);
      transform: rotate(225deg);
  }
  
  .burger-check:checked + .burger span:after {
      -webkit-transform: rotate(-225deg);
      -ms-transform: rotate(225deg);
      transform: rotate(-225deg);
  }
  

  .a-header {
      position: relative;
      float: left;
      width: calc(100%);
      margin: 0;
      background: #fff;
      overflow: hidden;
  }
  
  .a-header ul {
      position: relative;
      float: left;
      width: 100%;
      list-style: none;
      margin: 0;
      padding: 0;
  }
  
  .a-header ul a {
      position: relative;
      float: left;
      width: 100%;
      height: 0;
      line-height: 30px;
      padding: 0 15px;
      text-decoration: none;
      overflow: hidden;
      color: #71a600;
      text-align: left;
  }
  
  .a-header ul a:hover,
  .a-header ul a:active,
  .a-header ul a:focus,
  .a-header ul a:visited {
      text-decoration: none;
      background: #fff;
      border-bottom-color: #1C6402;
  }
  
  #main-nav:checked ~ ul a {
      height: 40px;
  }
  .popup {
    background-color: #fff;
    padding: 10px;
    border: 1px solid #ccc;
    position: absolute;
    top: 50%;
    left: 0%;
    height: 35px;
    transform: translate(15%, -50%);
    color: #1c6402;
    font-weight: 600;
  }
  .left-con {
    padding: 0px 30px;
  }
  }
  .pro-des{
  font-size: 16px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  font-family:"Mukta"
}

.pagelink {
  background-image: url("../../assets/img/drive/Detail-Banner.webp");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}


.pagelink h1 {
  color: #238601;
  font-family: "Mukta";
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.pagelink h6 {
  color: #238601;
  font-family: "Mukta";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.pagelink h6 a {
  text-decoration: none;
  color: #238601;
}

.pagelink h6 a:hover {
  text-decoration: none;
  color: #134801;
}

.no-data {
  font-size: 20px;
  font-weight: 600;
}

.no-data-para{
  font-size: 16px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.nodata-img{
  width: 200px !important;
  height: 200px;
  margin-left: auto;
  margin-right: auto;
}
.nodata-img .not-found-img{
  width: 100% !important;
    height: 100% !important;
  object-fit: cover;
}
.btns-soon{
  background: #71A600;
  color: white;
  font-family: 'satisfy';
  box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
  border: none;
  border-radius: 6px;
}

.btns-soon a{
  text-decoration: none;
  color: white;
}

</style>
<style module>
#more {
    margin-left: auto;
  margin-right: auto;
  width: 90%;
}
#more > h1{
  font-size: 28px;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 600;

}
#more > h2{
  font-size: 22px;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 600;

}
#more > h3{
  font-size: 18px;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 600;
}

#more > h4{
   font-size: 16px;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 600;
}

#more > ul > p >span{
    font-family: 'Work Sans', sans-serif !important;

}

#more > span{
  font-family: 'Work Sans', sans-serif !important;

}

#more > ul > li > h4{
  font-size: 16px;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 600;
}

#more > ul > li > h4 a{
 color: black;
}
#more > ol > li > h4 a{
 color: black;
}
#more > ol > li > h4{
  font-size: 16px;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 600;

}

#more > ol > li > span{
  font-size: 14px;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 600;
}

#more > ol > li {
  font-size: 14px;
  font-family: 'Work Sans', sans-serif !important;
}
#more > p{
  font-size: 14px;
  font-family: 'Work Sans', sans-serif !important;
 text-align: justify !important;
}

#more > ul >li{
  font-size: 14px;
  padding-bottom: 2%;
  font-family: 'Work Sans', sans-serif !important;
}

#more > ul > li > span{
  font-size: 14px;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 600;
}

#more > ul >li:nth-child(5){
  padding-bottom: 0%;
}

</style>